import PeriodicityEnum from '@/views/cashFlow/predictedFlow/enums/PeriodicityEnum';
import ReportTypesEnum from '@/views/cashFlow/predictedFlow/enums/ReportTypesEnum';
import ShowOptionsEnum from '@/views/cashFlow/predictedFlow/enums/ShowOptionsEnum';

export default class FilterParameterPredictedFlow {
  public constructor(
    public initialDate: string,
    public finalDate: string,
    public considerBankBalance: string,
    public periodicity: PeriodicityEnum,
    public show: ShowOptionsEnum|null,
    public reportType: ReportTypesEnum|null,
    public selectedCompanies: number[],
  ) {}

  public static make(data: any): FilterParameterPredictedFlow {
    return new FilterParameterPredictedFlow(
      data.initial_date,
      data.final_date,
      data.consider_bank_balance,
      data.periodicity,
      data.show ?? null,
      data.report_type ?? null,
      data.company_ids ? JSON.parse(data.company_ids) : [],
    );
  }
}
