














































































































import { defineComponent } from '@vue/composition-api';
import { mapState } from 'vuex';
import ISelectOptions from '@/domain/interfaces/ISelectOptions';
import IPredictedFlowFilters from '@/views/cashFlow/predictedFlow/interfaces/IPredictedFlowFilters';
import CompanyRepository from '@/repositories/CompanyRepository';
import FilterParametersRepository from '@/repositories/FilterParametersRepository';
import FilterParameterPredictedFlow from '@/domain/models/filter-parameters/FilterParameterPredictedFlow';
import GroupFilterParametersEnum from '@/domain/enums/GroupFilterParametersEnum';
import ReportTypesEnum from '@/views/cashFlow/predictedFlow/enums/ReportTypesEnum';
import ShowOptionsEnum from '@/views/cashFlow/predictedFlow/enums/ShowOptionsEnum';
import PeriodicityEnum from '@/views/cashFlow/predictedFlow/enums/PeriodicityEnum';
import { VForm } from '@/types/VForm';

export default defineComponent({
  props: {
    isLoading: Boolean,
  },
  data() {
    const initialDate = null;
    const finalDate = null;
    const considerBankBalance = null;
    const periodicity = PeriodicityEnum.DAILY;
    const show = null;
    const reportType = null;

    const selectedCompanies: number[] = [];

    const companyOptions: ISelectOptions<number>[] = [];
    const bankBalanceOptions: ISelectOptions[] = [
      { text: 'ERP', value: 'erp' },
      { text: 'Banco', value: 'bank' },
    ];
    const periodicityOptions: ISelectOptions[] = [
      { text: 'Diário', value: PeriodicityEnum.DAILY },
      { text: 'Semanal', value: PeriodicityEnum.WEEKLY },
      { text: 'Mensal', value: PeriodicityEnum.MONTHLY },
    ];
    const showOptions: ISelectOptions[] = [
      { text: 'Liberado', value: ShowOptionsEnum.RELEASED },
      { text: 'Não Liberado', value: ShowOptionsEnum.NOT_RELEASED },
    ];
    const reportTypeOptions: ISelectOptions<ReportTypesEnum>[] = [
      { text: 'Analítico', value: ReportTypesEnum.ANALYTICAL },
      { text: 'Sintético', value: ReportTypesEnum.SYNTHETIC },
    ];

    const companyRepository: CompanyRepository = new CompanyRepository();
    const filterParametersRepository: FilterParametersRepository = new FilterParametersRepository();

    return {
      initialDate: initialDate as string|null,
      finalDate: finalDate as string|null,
      considerBankBalance: considerBankBalance as string|null,
      periodicity: periodicity as PeriodicityEnum,
      show: show as ShowOptionsEnum|null,
      reportType: reportType as ReportTypesEnum|null,
      selectedCompanies,
      companyOptions,
      bankBalanceOptions,
      periodicityOptions,
      showOptions,
      reportTypeOptions,
      companyRepository,
      filterParametersRepository,
    };
  },
  computed: {
    ...mapState({
      companyGroupId: (state: any) => state.authentication.user.company_group_id as number,
    }),
  },
  async created() {
    Promise.all([
      this.loadCompanies(),
      this.getGroupFilterParameters(),
    ]);
  },
  methods: {
    async loadCompanies(): Promise<void> {
      const companies = await this.companyRepository.getCompaniesByLoggedUser(
        this.companyGroupId,
      );
      this.companyOptions = companies.map(({ name, id }) => ({
        text: name,
        value: id,
      }));
    },
    async getGroupFilterParameters(): Promise<void> {
      try {
        this.$dialog.startLoading();

        const filterParameters = await this.filterParametersRepository
          .getFilterByGroup(GroupFilterParametersEnum.PREDICTED_FLOW);

        if (!Array.isArray(filterParameters)) {
          const formattedFilters = FilterParameterPredictedFlow.make(filterParameters);

          this.applyFiltersOnActualPage(formattedFilters);
        }
      } catch (error) {
        this.$notification.error('Houve um problema ao requisitar os filtros dessa tela!');
      } finally {
        this.$dialog.stopLoading();
      }
    },
    applyFiltersOnActualPage(filters: FilterParameterPredictedFlow) {
      this.initialDate = filters.initialDate;
      this.finalDate = filters.finalDate;
      this.considerBankBalance = filters.considerBankBalance;
      this.periodicity = filters.periodicity;
      this.show = filters.show;
      this.reportType = filters.reportType;
      this.selectedCompanies = filters.selectedCompanies;
    },
    validate(): IPredictedFlowFilters|false {
      const predictedFlowFilterForm = this.$refs.PredictedFlowFilterForm as VForm;

      if (!predictedFlowFilterForm.validate()) {
        this.$notification.error('Há campos preenchidos incorretamente!');
        return false;
      }

      return {
        initialDate: this.initialDate!,
        finalDate: this.finalDate!,
        considerBankBalance: this.considerBankBalance,
        periodicity: this.periodicity,
        show: this.show,
        reportType: this.reportType,
        selectedCompanies: this.selectedCompanies,
      };
    },
  },
});
