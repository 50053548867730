import SelectOptions from '@/domain/interfaces/ISelectOptions';
import DDAConciliation from '@/domain/models/DDAConciliation';
import DataWithPaginationInfo from '@/domain/generic/DataWithPaginationInfo';
import Supplier from '@/domain/models/Supplier';
import ArtificialInvoice from '@/domain/models/ArtificialInvoice';
import DDAConciliationMovement from '@/domain/models/DDAConciliationMovement';

import { IConciliation, ICreateMovementInvoiceData } from '@/views/ddaConciliation/utils/interfaces';

import Repository from './base/Repository';

export default class DDAConciliationRepository extends Repository {
  async getCompanies(): Promise<Array<SelectOptions>> {
    const { status, data: response } = await this.datasource.get('dda/config/listAll');

    if (status == 200) {
      if (response.error) throw new Error(`Empresas: ${response.message}`);

      const companies = response.data.map((item: any) => {
        const { name, company_id } = item;
        return { text: name, value: company_id };
      });
      return companies;
    }
    throw new Error('Ops, algo inesperado aconteceu na listagem das empresas.');
  }

  async getSuppliers(
    groupId: number,
    companies: Array<number>,
    search: string,
  ): Promise<Array<SelectOptions>> {
    const { status, data: response } = await this.datasource.get(
      `company-groups/${groupId}/suppliers/search/like`,
      {
        headers: {
          'company-ids': JSON.stringify(companies || []),
        },
        params: {
          limit: 45,
          search,
          only_suppliers: Number(true),
        },
      },
    );

    if (status == 200) {
      if (response.error) throw new Error(`Fornecedores: ${response.message}`);

      const suppliers: Array<Supplier> = response.data.map(Supplier.make);

      const selectOptions = suppliers.map((supplier) => {
        const {
          id, name, code, document,
        } = supplier;
        return { value: id, text: `${code} - ${name} ${document ? `- ${document}` : ''}` };
      });

      return selectOptions;
    }
    throw new Error('Ops, algo inesperado aconteceu na listagem dos fornecedores.');
  }

  async getPaymentAccountsTypes(): Promise<Array<SelectOptions>> {
    const { status, data: response } = await this.datasource.get('accountsPayment/types');

    if (status == 200) {
      if (response.error) throw new Error(`Tipos de Conta: ${response.message}`);

      const types = response.data.map((type: any) => {
        const { e2_tipo: e2Tipo } = type;
        return { text: e2Tipo, value: e2Tipo };
      });

      return types;
    }
    throw new Error('Ops, algo inesperado aconteceu na listagem dos tipos de conta.');
  }

  async getDDAConciliations(
    groupId: number,
    companies: Array<number>,
    params: object,
  ): Promise<Array<DDAConciliation>> {
    const { status, data: response } = await this.datasource.get(
      `company-groups/${groupId}/direct-debits/conciliations/`,
      {
        headers: { 'company-ids': JSON.stringify(companies || []) },
        params,
      },
    );

    if (status == 200) {
      const conciliations: Array<DDAConciliation> = response.data.map(DDAConciliation.make);
      const conciliationsWithUniqueId = conciliations.map((item, index) => {
        item.uid = index + Math.random().toString(16).substring(2);
        return item;
      });
      return conciliationsWithUniqueId;
    }
    throw new Error('Ops, algo inesperado aconteceu na listagem das conciliações de DDA');
  }

  async getDDAs(
    groupId: number,
    companies: Array<number>,
    params: object,
  ): Promise<DataWithPaginationInfo<Array<DDAConciliationMovement>>> {
    const { status, data: response } = await this.datasource.get(
      `company-groups/${groupId}/direct-debits/conciliations/search/direct-debits`,
      {
        headers: { 'company-ids': JSON.stringify(companies ?? []) },
        params,
      },
    );

    if (status == 200) {
      const movements = response.data.map(DDAConciliationMovement.make);
      return { data: movements, meta: response.meta.total };
    }
    throw new Error('Ops, algo inesperado aconteceu na listagem dos DDAs.');
  }

  async getERPs(
    groupId: number,
    companies: Array<number>,
    params: object,
  ): Promise<DataWithPaginationInfo<Array<DDAConciliationMovement>>> {
    const { status, data: response } = await this.datasource.get(
      `company-groups/${groupId}/direct-debits/conciliations/search/erps`,
      {
        headers: { 'company-ids': JSON.stringify(companies ?? []) },
        params,
      },
    );

    if (status == 200) {
      const movements = response.data.map(DDAConciliationMovement.make);
      return { data: movements, meta: response.meta.total };
    }
    throw new Error('Ops, algo inesperado aconteceu na listagem dos ERPs.');
  }

  async getInvoice(groupId: number, company: number, id: string): Promise<ArtificialInvoice> {
    const { status, data: response } = await this.datasource.get(
      `company-groups/${groupId}/direct-debits/conciliations/invoices/${id}`,
      {
        headers: { 'company-id': company },
      },
    );

    if (status == 200) {
      const artificialInvoice = ArtificialInvoice.make(response.data);
      return artificialInvoice;
    }
    throw new Error('Ops, algo inesperado aconteceu na listagem da fatura.');
  }

  async deconciliation(group: number, companies: number[], id: number | string) {
    const { status } = await this.datasource.destroy(
      `company-groups/${group}/direct-debits/conciliations/`,
      {
        headers: { 'company-ids': JSON.stringify(companies ?? []) },
        params: {
          id,
        },
      },
    );

    if (status == 200) {
      return true;
    }
    throw new Error('Ops, algo inesperado aconteceu na desconciliação do DDA.');
  }

  async invoice(group: number, record: object) {
    const { status } = await this.datasource.send(
      `company-groups/${group}/direct-debits/conciliations/invoices`,
      record,
    );

    if (status == 201) {
      return true;
    }
    throw new Error('Ops, algo inesperado aconteceu na geração da fatura.');
  }

  async conciliation(group: number, company: number, record: IConciliation) {
    const { status } = await this.datasource.send(
      `company-groups/${group}/direct-debits/conciliations/`,
      record,
      {
        headers: {
          'company-id': company,
        },
      },
    );

    if (status == 200) {
      return true;
    }
    throw new Error('Ops, algo inesperado aconteceu durante a conciliação.');
  }

  async generateAccountPayable(group: number, company: number, record: ICreateMovementInvoiceData) {
    const { status } = await this.datasource.send(
      `company-groups/${group}/direct-debits/accounts-payable`,
      record,
      {
        headers: {
          'company-id': company,
        },
      },
    );

    if (status == 200) {
      return true;
    }
    throw new Error('Ops, algo inesperado aconteceu durante a conciliação.');
  }
}
