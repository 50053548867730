import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.showMenu && !_vm.showJustUndoConciliation)?_c(VMenu,{attrs:{"close-on-content-click":"","close-on-click":"","bottom":"","left":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onMenu = ref.on;
return [_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c(VBtn,_vm._g({attrs:{"icon":""}},Object.assign({}, onMenu, onTooltip)),[_c(VIcon,[_vm._v(" mdi-menu ")])],1)]}}],null,true)},[_c('span',[_vm._v("Opções")])])]}}],null,false,511091820)},[_c(VList,[(_vm.showResolve)?_c(VListItem,{on:{"click":function($event){return _vm.action(_vm.actionType.RESOLVE)}}},[_c(VListItemTitle,[_vm._v(" Conciliar ")])],1):_vm._e(),(_vm.showInvoice)?_c(VListItem,{on:{"click":function($event){return _vm.action(_vm.actionType.INVOICE)}}},[_c(VListItemTitle,[_vm._v(" Gerar Fatura ")])],1):_vm._e(),(_vm.showViewInvoice)?_c(VListItem,{on:{"click":function($event){return _vm.action(_vm.actionType.VIEW_INVOICE)}}},[_c(VListItemTitle,[_vm._v(" Visualizar Fatura ")])],1):_vm._e(),(_vm.showSearch)?_c(VListItem,{on:{"click":function($event){return _vm.action(_vm.actionType.SEARCH_ERP)}}},[_c(VListItemTitle,[_vm._v(" Procurar Título ")])],1):_vm._e(),(_vm.hasPermissionToGenerateAccountPayable && _vm.showInvoice)?_c(VListItem,{on:{"click":function($event){return _vm.action(_vm.actionType.GENERATE_INVOICE)}}},[_c(VListItemTitle,[_vm._v(" Gerar Título a Pagar ")])],1):_vm._e(),(_vm.showSearchDDA)?_c(VListItem,{on:{"click":function($event){return _vm.action(_vm.actionType.SEARCH_DDA)}}},[_c(VListItemTitle,[_vm._v(" Procurar DDA ")])],1):_vm._e(),(_vm.showRegisterBarcode)?_c(VListItem,{on:{"click":function($event){return _vm.action(_vm.actionType.BARCODE)}}},[_c(VListItemTitle,[_vm._v(" Cadastrar Cód. Barras ")])],1):_vm._e(),(_vm.showUndoConciliation)?_c(VListItem,{on:{"click":function($event){return _vm.action(_vm.actionType.UNDO)}}},[_c(VListItemTitle,[_vm._v(" Desfazer Conciliação ")])],1):_vm._e()],1)],1):_vm._e(),(_vm.showJustUndoConciliation)?_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.action(_vm.actionType.UNDO)}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(" mdi-close ")])],1)]}}],null,false,471307702)},[_c('span',[_vm._v("Desfazer Conciliação")])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }