enum DDAConciliationActionType {
  SEARCH_ERP = 'search-erp',
  SEARCH_DDA = 'search-dda',
  VIEW_INVOICE = 'view-invoice',
  INVOICE = 'invoice',
  RESOLVE = 'resolve',
  BARCODE = 'barcode',
  GENERATE_INVOICE = 'generate-invoice',
  UNDO = 'undo',
}

export default DDAConciliationActionType;
