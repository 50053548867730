<template>
    <div class="m-grid m-grid--hor m-grid--root m-page" style="height: 100%;">

        <div class="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body pt-0 bg-grey-200">

            <div class="m-grid__item m-grid__item--fluid m-wrapper" id="fluxApp">

                 <div>
                     <page-container>
                        <router-view></router-view>
                     </page-container>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import PageContainer from '../../../src/components/defaults/PageContainer.vue';
    import { actions } from '../../../src/components/notifications/notification.state';
    import { API_BASE, API_IMPORT } from '@config/api';
    import { APP_VERSION } from '@config/info';

    export default {     
        computed: mapState('authentication', ['user']), 
        components: { PageContainer },

        watch: {
            'user': function () {
                this.updateData()
            }
        },

        data () {
            return {
                userName: this.$session.get('name'),
                userEmail: this.$session.get('email'),
                userGravatar: this.$session.get('user_gravatar'),
                companyGroupImage: this.$session.get('company_group_image'),
                innCashVersion: APP_VERSION ?? '1.0.0',
            }
        },
        mounted: function () {
            this.loadMethod();

        },
        methods: {
            loadMethod: function () {
                var self = this;

                $("#notification").click(function(){
                    if (!$(this).hasClass('m-dropdown--open')) {
                        blockPage("Carregando os dados...");

                        $.ajax({
                            method: "GET",
                            url: API_BASE+'notification/listAll',
                            headers: {
                                'token': self.$session.get('token')
                            },
                        }).done(function(result) {
                            self.notifications = result.data;
                            self.newNotifications = true;

                            $.ajax({
                                method: "GET",
                                url: API_BASE+'notification/readAll',
                                headers: {
                                    'token': self.$session.get('token')
                                },
                            }).done(function(result) {
                                self.newNotifications = false;
                                unblockPage();
                            }).fail(function(error, status){
                                console.log(status);
                                console.log(error);
                                unblockPage();
                            });
                        }).fail(function(error, status){
                            console.log(status);
                            console.log(error);
                            unblockPage();
                        });
                    }
                });
            },

            updateData: function () {
                var self = this;

                self.userName = self.$session.get('name')
                self.userGravatar = self.$session.get('user_gravatar')
            },

            importData: function(){
                var self = this;
                var url = API_IMPORT + 'import?companyid='+self.$session.get('company_group_id');

                $.ajax({
                    type: 'POST',
                    url: API_BASE + 'job/callErp',
                    data: {
                        method: 'GET',
                        url: JSON.stringify(url)
                    },
                    success: function (result) {
                        if (result.error) {
                            showNotification('Atenção',result.message, 'danger');
                            return false;
                        }

                        showNotification('Sucesso', result.message, 'success');
                    },
                    error: function (error) {
                        console.log('error');
                        console.log(error);

                        showNotification('Atenção',error.responseJSON.message, 'danger');
                        return false;
                    }
                });
            },
        }
    }
</script>

<style lang="sass">
.m
    &-btn
        color: white !important

    &-btn--icon
        color: white !important

    &-btn--air
        color: white !important

    &-grid
        background-color: rgb(242, 243, 248)

    &-footer
        margin-left: 0px !important

</style>