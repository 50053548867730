import qs from 'qs';
import Repository from '@/repositories/base/Repository';
import ILaravelOrdenationTableParams from '@/repositories/parameters/ILaravelOrdenationTableParams';
import Client from '@/domain/models/Client';
import ClientSaleOrders from '@/domain/models/ClientSaleOrders';
import DataWithPaginationInfo from '@/domain/generic/DataWithPaginationInfo';
import IWebhookBaseParams from '@/repositories/parameters/IWebhookBaseParams';
import GetClientSalesOrderFilterParams from '@/views/clients/types/GetClientSalesOrderFilterParams';
import UpdateClientInfoParams from '@/views/clients/types/UpdateClientInfoParams';
import IUpdateCustomerOrderInformation from '@/views/orders/interfaces/IUpdateCustomerOrderInformation';
import SearchCustomer from '@/domain/models/SearchCustomer';
import ClientContact from '@/domain/models/ClientContact';
import StatusType from '@/domain/enums/StatusType';

export default class ClientRepository extends Repository {
  public async getAllClients(
    companyGroupId : number,
    companiesToConsider: number[],
    dataTableConfig: ILaravelOrdenationTableParams,
  ): Promise<DataWithPaginationInfo<Client[]>> {
    const { status, data: response } = await this.datasource.get(`company-groups/${companyGroupId}/customers`, {
      headers: { 'company-ids': JSON.stringify(companiesToConsider) },
      params: dataTableConfig,
      paramsSerializer: qs.stringify,
    });

    if (!this.isStatusOK(status)) {
      throw new Error('Aconteceu um erro inesperado');
    }

    return {
      data: response.data.map(Client.make),
      meta: response.meta,
    };
  }

  public async getClientById(
    customerId: string,
    companyGroupId: number,
    companyIds: number[],
  ): Promise<Client> {
    const { status, data: request } = await this.datasource.get(`company-groups/${companyGroupId}/customers/${customerId}`, {
      headers: { 'company-ids': JSON.stringify(companyIds) },
    });

    if (!this.isStatusOK(status)) {
      throw new Error('Aconteceu um erro inesperado');
    }

    return Client.make(request.data, true);
  }

  public async getCustomerByDocumentRoot(
    documentRoot: string,
    companyGroupId: number,
    companyIds: number[],
  ): Promise<Client> {
    const { status, data: request } = await this.datasource.get(`company-groups/${companyGroupId}/customers-document/${documentRoot}`, {
      headers: { 'company-ids': JSON.stringify(companyIds) },
    });

    if (!this.isStatusOK(status)) {
      throw new Error('Aconteceu um erro inesperado');
    }

    return Client.make(request.data, true);
  }

  public async getClientSells(
    customerId: string,
    moduleId: string,
    companyId: number,
    dataTableConfig: GetClientSalesOrderFilterParams,
  ): Promise<{ data: ClientSaleOrders[], total: number }> {
    const { status, data: response } = await this.datasource.get(`company-groups/${moduleId}/customers/${customerId}/sales-order`, {
      params: dataTableConfig,
      headers: { 'company-id': companyId },
    });

    if (!this.isStatusOK(status)) {
      throw new Error('Aconteceu um erro inesperado');
    }

    return {
      data: response.data.map(ClientSaleOrders.make),
      total: response.totalRows,
    };
  }

  public async getAllCustomersBySearch(
    companyGroupId: number,
    companyId: number,
    search: string,
  ): Promise<SearchCustomer[]> {
    const { data: response } = await this.datasource.get(`company-groups/${companyGroupId}/customers/search/like`, {
      params: { search },
      headers: { 'company-id': companyId },
    });

    return response.data.map(SearchCustomer.make);
  }

  public async updateCustomerAdditionalInformation(
    companyId: number,
    companyGroup: number,
    params: IWebhookBaseParams<UpdateClientInfoParams>,
  ): Promise<void> {
    await this.datasource.update(`company-groups/${companyGroup}/customers/additional-information`, params, {
      headers: { 'company-id': JSON.stringify(companyId) },
    });
  }

  public async updateCustomerOrderInformation(
    companyId: number,
    companyGroup: string,
    params: IWebhookBaseParams<IUpdateCustomerOrderInformation>,
  ): Promise<void> {
    await this.datasource.update(`company-groups/${companyGroup}/customers/order-information`, params, {
      headers: { 'company-id': JSON.stringify(companyId) },
    });
  }

  public async getCustomerContacts(
    group: number,
    company: number,
    customer: string,
  ): Promise<Array<ClientContact>> {
    const { status, data: response } = await this.datasource.get(
      `company-groups/${group}/customers/contacts/${customer}`,
      { headers: { 'company-id': company } },
    );

    if (this.isStatusOK(status)) {
      return response.data.map(ClientContact.make);
    }

    throw new Error('Ops, algo inesperado ocorreu durante a listagem de contatos!');
  }

  public async sendCustomerContact(
    group: number,
    company: number,
    customer: string,
    record: Partial<ClientContact>,
  ): Promise<ClientContact> {
    const { status, data: response } = await this.datasource.send(
      `company-groups/${group}/customers/contacts/${customer}`,
      record,
      { headers: { 'company-id': company } },
    );

    if (this.isStatusOK(status)) {
      return response.data;
    }

    throw new Error('Ops, algo inesperado ocorreu durante a criação do contato!');
  }

  public async updateCustomerContact(
    group: number,
    company: number,
    customer: string,
    contact: number,
    record: Partial<ClientContact>,
  ): Promise<ClientContact> {
    const { status, data: response } = await this.datasource.update(
      `company-groups/${group}/customers/contacts/${contact}`,
      record,
      { headers: { 'company-id': company } },
    );

    if (this.isStatusOK(status)) {
      return response.data;
    }

    throw new Error('Ops, algo inesperado ocorreu durante a atualização do contato!');
  }

  public async destroyCustomerContact(
    group: number,
    company: number,
    customer: string,
    contact: number,
  ): Promise<ClientContact> {
    const { status, data: response } = await this.datasource.destroy(
      `company-groups/${group}/customers/${customer}/contacts/${contact}`,
      { headers: { 'company-id': company } },
    );

    if (this.isStatusOK(status)) {
      return response.data;
    }

    throw new Error('Ops, algo inesperado ocorreu durante a exclusão do contato!');
  }
}
